import React from "react";
import { Button } from "@mui/material";
import "./Welcome.css";

function Welcome() {
  // Telegram WebApp obyekti
  const tg = window.Telegram?.WebApp;

  // URL’dan user_id va telegram_id ni olish (kerak bo‘lsa, keyinchalik kerakli joyda ishlatishingiz mumkin)
  const queryParams = new URLSearchParams(window.location.search);
  const userId = parseFloat(queryParams.get("user_id"));
  const telegramId = parseFloat(queryParams.get("telegram_id"));

  const goToPassenger = () => {
    // Telegram botga /start komandasini yuboramiz
    tg?.sendData("/start");
    // Ilovani yopish
    tg?.close();
  };

  const goToDriver = () => {
    // Telegram botga /start komandasini yuboramiz
    tg?.sendData("/start");
    // Ilovani yopish
    tg?.close();
  };

  return (
    <div className="welcome-page">
      <div className="welcome-header">
        <h1 className="welcome-title">Birgayurga Xush kelibsiz!</h1>
        <p className="welcome-subtitle">
          Oson va samarali shaharlar a'ro sayohatlar uchun mo'ljallangan bot.
        </p>
      </div>
      <div className="welcome-buttons">
        <Button
          variant="contained"
          size="large"
          onClick={goToPassenger}
          sx={{ marginRight: 2 }}
        >
          Yo‘lovchi
        </Button>
        <Button variant="outlined" size="large" onClick={goToDriver}>
          Haydovchi
        </Button>
      </div>
    </div>
  );
}

export default Welcome;
